<style>
.spinner {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.spinner:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 6px;
  box-sizing: border-box;
  border: 26px solid #7e4924;
  border-color: #7e4924 transparent #7e4924 transparent;
  animation: lds-hourglass 1.2s infinite;
}
@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>

<div class="spinner-container">
    <div class="spinner"></div>
</div>
